import axios from "axios";
import authHeader from "./auth-header";
import {API_URL} from "../config";

const usersUrl = ({complete}) => {
    return `${API_URL}users${complete ? '?complete=true':''}`
}

const userDataUrl = (id) => {
    return `${API_URL}user-data/${id}`
}

const updateUserDataUrl = (user_id) => {
    return `${API_URL}user/${user_id}`
}

const getPublicContent = () => {
    return axios.get(API_URL + "all");
};

const getUsers = () => {
    return axios.get(this.usersUrl(), { headers: authHeader() })
};

const getUser = (id) => {
    return axios.get(this.userDataUrl(id), { headers: authHeader() })
};

const UserService = {
    usersUrl,
    userDataUrl,
    updateUserDataUrl,
    getPublicContent,
    getUsers,
    getUser
};

export default UserService;
