import React from "react";
import _ from "lodash";
import {fieldTypes} from "../services/utils";
import {
    Box, Button,
    Checkbox, Chip,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid, IconButton,
    MenuItem, Radio, RadioGroup,
    Select,
    Slide,
    TextField, Tooltip, Typography
} from "@mui/material";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {MobileDatePicker} from "@mui/x-date-pickers/MobileDatePicker";
import {LocalizationProvider, MobileDateTimePicker, TimePicker} from "@mui/x-date-pickers";
import {ContentCopy, VideoCameraFront} from "@mui/icons-material";
import {useParams} from "react-router-dom";

const CustomField = ({formik, field}) => {
    const {id: userId} = useParams()

    const fieldType = _.find(fieldTypes, ['type', field.type])

    function getOptions() {
        switch (field.id) {
            case "luogoArrivo":
                return formik.values['mezzoDiTrasporto'] === "In aereo" ? ["Linate", "Malpensa"]
                    : formik.values['mezzoDiTrasporto'] === "In treno" ? [ "Milano Centrale", "Milano Garibaldi" ]
                        : (field?.values || [])
            default:
                return field?.values || []

        }
    }

    function getValue() {
        return field.getter ? formik.values[field.getter][field.id] : formik.values[field.id]
    }

    function getOtherValue() {
        const val = getValue()
        switch (field.id) {
            case "luogoArrivo":
                return getOptions().includes(val) ? "" : val
            case "luogoPartenzaRitorno":
                return field.values.includes(val) ? "" : val
            default:
                return val

        }
    }

    //console.log("userId:",userId)
    //console.log("formik:",formik.values)

    return(
        <Slide in={true} exit={true}
            children={<Grid item xs={12} md={field?.md || fieldType?.md || 6}>
                {(() => {
                    switch (fieldType) {
                        case fieldTypes.RADIO_GROUP:
                            return <FormControl fullWidth>
                                <Typography variant={'body2'}>{field.label}</Typography>
                                <RadioGroup
                                    row
                                    value={formik.values[field.id]}
                                    onChange={(event, value) => {
                                        formik.setFieldValue(field.id, value)
                                        formik.setFieldTouched(field.id)
                                    }}
                                    onBlur={formik.handleBlur}
                                    name={field.id}
                                >
                                    {
                                        (field.values || getOptions()).map((radio) =>
                                            <FormControlLabel key={radio} value={radio} control={
                                                <Radio required/>} label={radio}/>
                                        )
                                    }
                                    {field.other
                                        && (field.condition && formik.values[field.condition.id] === field.condition.value)
                                        && <FormControlLabel
                                            checked={!getOptions().includes(getValue())}
                                            control={<Radio/>} label={<TextField
                                            name={field.getter ? `${field.getter}.${field.id}` : field.id}
                                            value={getOtherValue()}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            variant={"standard"} placeholder={'Altro:'}/>
                                        }/>}
                                </RadioGroup>
                            </FormControl>
                        case fieldTypes.CHECK_BOX:
                            return <FormControl fullWidth>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox
                                            id={field.id}
                                            name={field.id}
                                            disabled={field.precompiled}
                                            checked={formik.values[field.id]}
                                            //onChange={formik.handleChange}
                                            //onBlur={formik.handleBlur}
                                            onChange={(event, checked) => {
                                                formik.setFieldValue(field.id, checked)
                                                formik.setFieldTouched(field.id)
                                            }}
                                        />} label={field.label}/>
                                </FormGroup>
                            </FormControl>
                        case fieldTypes.SELECT:
                            return <FormControl variant="standard" fullWidth>
                                <FormHelperText>{field.label}</FormHelperText>
                                <Select
                                    //disabled={field.precompiled}
                                    label={field.label}
                                    id={field.id}
                                    name={field.id}
                                    //value={dati[field.userId]}
                                    value={formik.values[field.id]}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    //error={Boolean(formik.errors[field.userId])}
                                    //helperText={formik.errors[field.userId] ? formik.errors[field.userId] : null}
                                    //helperText={field.label}
                                >
                                    {(field?.values || getOptions()).map(v =>
                                        <MenuItem value={v} key={v}>{v}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        case fieldTypes.DATE:
                            return <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <MobileDatePicker
                                    renderInput={(params) =>
                                        <TextField {...params} id="name-input"
                                                   variant={'standard'}/>}
                                    label={field.label}
                                    onChange={(date, selectionState) => {
                                        //console.log("formatMySqlDate:",formatMySqlDate(date, formik.values.startingTime))
                                        formik.setFieldValue(field.id, date)
                                        formik.setFieldTouched(field.id)
                                    }}
                                    disabled={field.precompiled}
                                    value={new Date(formik.values[field.id]?._seconds ?
                                        formik.values[field.id]._seconds*1000
                                        : formik.values[field.id]
                                    ) || ''}
                                    inputFormat="dd/MM/yyyy"/>
                            </LocalizationProvider>
                        case fieldTypes.DATE_TIME:
                            return <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <MobileDateTimePicker
                                    renderInput={(params) =>
                                        <TextField {...params} id="name-input"
                                                   variant={'standard'}/>}
                                    label={field.label}
                                    onChange={(date, selectionState) => {
                                        formik.setFieldValue(field.id, date)
                                        formik.setFieldTouched(field.id)
                                    }}
                                    ampm={false}
                                    disabled={field.precompiled}
                                    value={new Date(formik.values[field.id]?._seconds ?
                                        formik.values[field.id]._seconds*1000
                                        : formik.values[field.id]
                                    ) || ''}
                                    inputFormat="dd/MM/yyyy, HH:mm"/>
                            </LocalizationProvider>
                        case fieldTypes.TIME:
                            return <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <TimePicker
                                    renderInput={(params) =>
                                        <TextField {...params} id="name-input"
                                                   variant={'standard'}/>}
                                    label={field.label}
                                    onChange={(date, selectionState) => {
                                        formik.setFieldValue(field.id, date)
                                        formik.setFieldTouched(field.id)
                                    }}
                                    ampm={false}
                                    disabled={field.precompiled}
                                    value={new Date(formik.values[field.id]) || ''}
                                    inputFormat="HH:mm"/>
                            </LocalizationProvider>
                        case fieldTypes.CUSTOM:
                            if(formik.values.partecipazione === 'Da remoto')
                                return <Box display={'flex'} justifyContent={'start'}>
                                    <Button href={`https://believetoachieve2023.canon.it/live/${userId}`}
                                            target={'_blank'}
                                            endIcon={<VideoCameraFront/>}
                                    >
                                        Link allo streaming
                                    </Button>
                                    <Tooltip title={"Copia link"}>
                                        <IconButton color={'primary'} onClick={async (event) => {
                                            await navigator.clipboard.writeText(`https://believetoachieve2023.canon.it/live/${userId}`)
                                        }}>
                                            <ContentCopy fontSize={'small'}/>
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            else if(formik.values.partecipazione === 'In presenza')
                                return formik.values.qrcodeUrl ? <Box display={'flex'} justifyContent={'start'} alignItems={'center'}>
                                    <Tooltip title={'Apri Qr Code in un\'altra finestra'}>
                                        <Button href={formik.values.qrcodeUrl}
                                                target={'_blank'}
                                                sx={{padding:1.5, backgroundColor: 'lightgray'}}
                                                onClick={(event) => {event.stopPropagation()}}>
                                            <img src={formik.values.qrcodeUrl} style={{maxWidth: '100px'}}/>
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title={"Copia link al Qr Code"}>
                                        <IconButton color={'primary'} onClick={() => navigator.clipboard.writeText(formik.values.qrcodeUrl)
                                        }>
                                            <ContentCopy fontSize={'small'} />
                                        </IconButton>
                                    </Tooltip>
                                </Box> : <Chip label={'Nessun QR Code disponibile per questo partecipante'}/>
                        default:
                            return <TextField disabled={field.precompiled}
                                              id={field.id}
                                              name={field.id}
                                              //multiline={field.multiline}
                                              value={formik.values[field.id]}
                                              onChange={formik.handleChange}
                                              onBlur={formik.handleBlur}
                                              label={field.label}
                                              variant={"standard"}
                                              sx={{width:'100%'}}/>
                    }
                })()}
            </Grid>}
        />)
}

export default CustomField