import React, {useEffect, useMemo, useState} from "react";
import {useNavigate} from 'react-router-dom'
import {alpha, Backdrop, Box, Button, Chip, Container, Fab, Snackbar, Tab, Tabs, Typography} from "@mui/material";
import logo from '../../assets/images/logo.png'
import {DataGrid, itIT} from '@mui/x-data-grid'
import UserService from "../../services/user.service";
import {Alert} from "@mui/lab";
import CustomGridToolbar from "../../components/CustomGridToolbar";
import {canonTheme} from "../../theme/canonTheme";
import useAxios from "axios-hooks";
import ColumnsService from "../../services/columns.service";
import {GridRowsProp} from "@mui/x-data-grid/models/gridRows";
import _ from 'lodash'
import {CheckCircle, Clear, Refresh} from "@mui/icons-material";
import {API_URL} from "../../config";
import axios from "axios";

export const forms = {
    ISCRIZIONI: {label: "Iscrizioni", index: 0},
    CHECKIN: {label: "Check-in", index: 1},
}

const Dashboard = () => {
    let navigate = useNavigate()

    const [activeTab, setActiveTab] = React.useState(forms.ISCRIZIONI.index);
    const handleChangeActiveTab = (event, newValue) => {
        setActiveTab(newValue);
    };

    const [pageSize, setPageSize] = useState(10)
    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({});

    const [{data: config, loading: loadingConfig}, refetchConfig] = useAxios(ColumnsService.columnsUrl(), {
        method: "GET", useCache: false
    })

    const [{data: allUsers, loading: loadingUsers, error: errorUsers}, fetchUsers] = useAxios(
        UserService.usersUrl({complete: true}), { method: "GET", useCache: false, manual: false }
    )

    const [error, setError] = useState(null)
    function handleCloseError() {
        setError(null)
    }

    const checkinUser = async (id) => {
        //console.log("id:",id)
        await axios.get(API_URL + "participantsFisici/" + id + "/checkin")
            .then(() => fetchUsers())
            .catch(err => {
                console.log("err:",err)
                setError(err.response?.data?.error || "Errore")
            })
    };

    const resetCheckInUser = async (id) => {
        //console.log("id:",id)
        await axios.get(API_URL + "participantsFisici/" + id + "/reset")
            .then(() => fetchUsers())
            .catch(err => setError(err.response))
    };

    const columns = useMemo(() => {
        const cols = ColumnsService.getColumns(activeTab)
        if(activeTab === forms.CHECKIN.index) {
                cols.push({
                field: "action",
                headerName: "Action",
                sortable: false,
                flex: 1,
                renderCell: (params) => {
                    const onClick = async (e) => {
                        e.stopPropagation(); // don't select this row after clicking
                        await checkinUser(params.id)
                    };
                    const onClickReset = async (e) => {
                        e.stopPropagation(); // don't select this row after clicking
                        await resetCheckInUser(params.id)
                    };

                    return !params.row.checkin ?
                        <Button variant={"outlined"}
                                endIcon={<CheckCircle sx={{ color: 'darkseagreen', fontSize: '2.5rem' }} />}
                                onClick={onClick}>Check-in</Button> :
                        <Button variant={"outlined"}
                                endIcon={<Clear sx={{ color: 'red', fontSize: '2.5rem' }} />}
                                onClick={onClickReset}>Reset Check-in</Button>;
                }
            })
        }
        return cols
    }, [activeTab])

    useEffect(() => {
        if(config)
            setColumnVisibilityModel(config.defaultVisibilityModel)
    }, [config])

    const users = useMemo(() => {
        if(!allUsers)
            return []
        else return _.orderBy(allUsers.filter(function (o) {
            return !o.roles || o.test === true// && !o.test
        }), 'completedAt._seconds', 'desc')
    }, [allUsers])

    const usersPerForm = useMemo(() => {
        if(!users)
            return []

        const res = []
        res[forms.ISCRIZIONI.index] = users
        res[forms.CHECKIN.index] = _.orderBy(_.filter(users, ['partecipazione', 'In presenza']), ['checkin', 'updatedAt._seconds'], ['asc','desc'])
        return res
    }, [users])

    const [filteredUsers, reports] = useMemo(() => {
        if (!usersPerForm)
            return [[], null]

        const _filteredUsers = usersPerForm[activeTab]
        let _reports = {}

        if (forms.CHECKIN.index === activeTab) {
            _reports = {
                'Partecipanti fisici': _.filter(_filteredUsers, ['partecipazione', 'In presenza']).length,
                'Check-in effetttuati': _.filter(_filteredUsers, ['checkin', true]).length,
            }
        } else {
            _reports = {
                'Utenti totali': _filteredUsers.length,
                'Partecipanti fisici': _.filter(_filteredUsers, ['partecipazione', 'In presenza']).length,
                'Partecipanti in streaming': _.filter(_filteredUsers, ['partecipazione', 'Da remoto']).length,
                'Check-in effetttuati': _.filter(_filteredUsers, ['checkin', true]).length,
            }
        }

        return [_filteredUsers, _reports]
    }, [usersPerForm, activeTab])

    const handleSaveConfiguration = (newModel) => {
        setTimeout(function () {
            ColumnsService.saveDefaultVisibilityModel(newModel)
                .catch((err) => {
                    console.log("err:",err)
                    //setMessage({show: true, loading: false, text: "Configurazione non salvata", severity: "error"})
                })
                .finally(() => setColumnVisibilityModel(newModel))
        }, 200);
    }

    return (
        <main>
            <Backdrop open={loadingUsers} sx={{zIndex: 10000}}/>
            <Snackbar open={!!error} autoHideDuration={6000} onClose={handleCloseError}>
                <Alert elevation={6} variant={"filled"} onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
                    {error || 'Ops, qualcosa è andato storto.'}
                </Alert>
            </Snackbar>
            <Box style={{background: alpha(canonTheme.palette.primary.main, 1)}}>
                <Container maxWidth={false}>
                    <Box width={'100%'} maxWidth={'90px'}>
                        <img src={logo} width={'100%'}
                             style={{margin: '1rem 0', borderRadius: '0.6rem', filter: 'brightness(0) invert(1)'
                        }}/>
                    </Box>
                </Container>
            </Box>
            <Container sx={{textAlign: 'center', paddingTop: '2rem', background: 'white'}} maxWidth={false}>
                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 1}}>
                    <Typography variant={"h4"} sx={{textAlign: 'left'}}>Elenco Iscrizioni</Typography>
                    <Button onClick={fetchUsers} variant={'contained'} endIcon={<Refresh/>}>
                        Aggiorna
                    </Button>
                </Box>
                <div style={{display: 'flex', height: 670, marginTop: '1rem'}}>
                    <div style={{flexGrow: 1}}>
                        <Tabs value={activeTab} onChange={handleChangeActiveTab} aria-label="disabled tabs example">
                            {
                                Object.values(forms).map((form) => {
                                        if(form.index === forms.CHECKIN.index)
                                            return <Tab key={form.index}
                                                        label={`${form.label} (${_.filter(usersPerForm[form.index], ['checkin', true])?.length} / ${usersPerForm[form.index]?.length})`}/>
                                        return <Tab key={form.index}
                                                    label={`${form.label} (${usersPerForm[form.index]?.length})`}/>
                                    }
                                )
                            }
                        </Tabs>
                        <Box my={1} display={'flex'} flexDirection={'row'} justifyContent={'flex-start'} alignItems={'baseline'}>
                        {
                                Object.entries(reports || []).map(([key, value], i) => {
                                    return <Chip key={i} variant={'outlined'} color={'primary'} label={`${key}: ${value || 0}`} sx={{marginRight: 0.5}}/>
                                })
                        }
                        </Box>
                        <DataGrid
                            localeText={{
                                ...itIT.components.MuiDataGrid.defaultProps.localeText,
                                toolbarExport: "Esporta colonne visibili"
                            }}
                            loading={loadingUsers || loadingConfig}
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={(newModel) =>
                                handleSaveConfiguration(newModel)
                            }
                            disableSelectionOnClick
                            onRowClick={(params, event, details) => {
                                navigate("/dashboard/user/" + params.row.id);
                                // TODO: Show row details
                            }}
                            components={{
                                Toolbar: () => {
                                    return <CustomGridToolbar/>
                                }
                            }}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[10, 20, 50]}
                            columns={!loadingConfig ? columns : []}
                            rows={filteredUsers}
                            //checkboxSelection={true}
                        />
                    </div>
                </div>
                <Fab onClick={fetchUsers}
                    color={'primary'}
                    size={"small"}
                    sx={{
                        top: 'auto',
                        right: '20px',
                        bottom: '40px',
                        left: 'auto',
                        position: 'fixed',
                        zIndex: 1010,
                    }}>
                    <Refresh/>
                </Fab>
            </Container>
        </main>
    )
}

export default Dashboard
