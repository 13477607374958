import React, {useEffect, useState} from "react";
import {Alert, AlertTitle, Box, Chip, Container, Divider, ListItem, ListItemIcon, Typography} from "@mui/material";

import {QrReader} from 'react-qr-reader';
import {API_URL} from "../../config";
import axios from "axios";
import SearchUserDialog from "../../components/SearchUserDialog";
import ConfirmCheckinDialog from "../../components/ConfirmCheckinDialog";
import CheckinAppBar from "../../components/CheckinAppBar";
import {Headset, HeadsetOff, LunchDining, NoMeals, Restaurant} from "@mui/icons-material";
import ListItemText from "@mui/material/ListItemText";


const Mobile = () => {

    const [count, setCount] = useState(0);
    const [data, setData] = useState('');
    const [user, setUser] = useState(null);
    const [userList, setUserList] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [confirmOpen, setConfirmOpen] = React.useState(false);
    const [error, setError] = useState(false);
    const constraints = {
        facingMode: "environment"
    };

    const getUser = async (id) => {
        const _user = await axios.get(API_URL + "participantsFisici/" + id);
        setUser(_user.data)
    };

    const checkinUser = async (id) => {
        setError(false);
        setUser(null)
        try {
            await axios.get(API_URL + "participantsFisici/" + id + "/checkin");
            return true;
        } catch (e) {
            if(e.response.status === 409) {
                console.log(e);
                return true;
            } else {
                setError(e);
                return false;
            }
        }
    };

    useEffect(() => {
        fetchData().then()
    }, [user])

    useEffect(() => {
        if (data !== '') {
            checkinUser(data).then(resCheckin => {
                if (resCheckin) {
                    getUser(data).then(_ => setCount(count + 1))
                    setConfirmOpen(true);
                }
            });
        }
    }, [data])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (res) => {
        if (res && res.hash) {
            checkinUser(res.hash)
                .then(resCheckin => {
                    if (resCheckin) {
                        getUser(res.hash).then(_ => setCount(count + 1))
                        setConfirmOpen(true);
                    }
                });
        }
        setOpen(false);
    };

    async function fetchData() {
        const _userList = await axios.get(API_URL + "participantsFisici");
        setUserList(_userList.data)
    }

    return (
        <main>
            <CheckinAppBar handleDialogOpen={handleClickOpen} />
            <Box>
                <Container maxWidth={false}>
                    <QrReader
                        onResult={(result, error) => {
                            if (!!result) {
                                //console.log("result:",result)
                                setData(result?.text);
                            }

                            if (!!error) {
                                //console.log(error);
                            }
                        }}
                        videoContainerStyle={{width: '100%', minHeight: '65vh'}}
                        constraints={constraints}
                    />
                </Container>
                {count > 0 && !error &&
                    <>
                        <Alert severity="success" sx={{m: 2}}>
                            <AlertTitle>
                                {user?.nome} {user?.cognome}
                            </AlertTitle>
                            {user?.email}
                        </Alert>
                        <Box px={2} pb={2}>
                            <Divider/>
                            <ListItem disablePadding>
                                <ListItemIcon>
                                    {user?.lunch === 'Si' ? <Restaurant/> : <NoMeals/>}
                                </ListItemIcon>
                                <ListItemText
                                    primary={"Pranzo: "+ (user?.lunch || '-')}
                                    secondary={user?.lunch && user?.lunch === 'Si' ? ("Intolleranze: " + (user?.intolleranze || '-')) : ''}
                                />
                            </ListItem>
                            <Divider/>
                            <ListItem disablePadding>
                                <ListItemIcon>{user?.cuffie === true ? <Headset/> : <HeadsetOff/>}</ListItemIcon>
                                <ListItemText primary={"Cuffie: "+
                                    (user?.cuffie === true ? 'Si'
                                        : user?.cuffie === false ? 'No'
                                            : '-')}/>
                            </ListItem>
                            <Divider/>
                        </Box>
                    </>}
                {error &&
                    <Alert severity={"error"} sx={{m: 2}}>
                        <AlertTitle>QR Code non valido.</AlertTitle>
                        {error.response.data.error}
                    </Alert>}
            </Box>
            <SearchUserDialog open={open} handleClose={handleClose} users={userList} />
            <ConfirmCheckinDialog open={confirmOpen} handleClose={() => setConfirmOpen(false)} user={user ? user : null} />
        </main>
    )
}

export default Mobile
