import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from 'react-router-dom'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary, alpha,
    Box,
    Button,
    Card,
    Chip,
    Container,
    Fab,
    Grid,
    Snackbar,
    Typography,
    useTheme
} from "@mui/material";
import logo from '../../assets/images/logo.png'
import UserService from "../../services/user.service";
import {ArrowBack, ExpandMore, Save} from "@mui/icons-material";
import {Alert} from "@mui/lab";
import useAxios from "axios-hooks";
import CustomLoader from "../../components/CustomLoader";
import formStructure from '../../data/formStructure.json'
import CustomField from "../../components/CustomField";
import {useFormik} from "formik";
import _ from 'lodash'
import CustomizedTimeline from "../../components/CustomTimeline";
import {canonTheme} from "../../theme/canonTheme";

const User = () => {
    const {id} = useParams() //dc402b1dcaea136215f9b588a25c76ec
    const theme = useTheme()
    let navigate = useNavigate()

    const [message, setMessage] = useState({show: false, text: "", severity: "success"})

    const [{data: userData, loading: loadingUserData, error: errorUserData}, fetchUser] = useAxios(
        UserService.userDataUrl(id), {method: "GET", useCache: false}
    )

    const [{data: userDto, loading: loadingUpdate, error: errorUpdate}, updateUser] = useAxios({},
        {manual: true}
    )

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setMessage({...message, show: false});
    };

    const formik = useFormik({
        initialValues: userData,
        enableReinitialize: true,
        //validationSchema: // TODO
    });

    const isValid = () => {
        return formik.dirty && formik.isValid
    }

    const handleSubmitForm = () => {
        //console.log('formik:',formik)
        if(isValid()) {
            //console.log("formik:",formik)
            const dto = {}
            Object.keys(formik.touched).forEach((touchedField) => {
                if(formik.values[touchedField] !== formik.initialValues[touchedField])
                    dto[touchedField] = formik.values[touchedField]
            })

            //console.log("dto:", dto)
            if(!_.isEmpty(dto)) {
                updateUser({
                    data: {data: dto, id: id},
                    url: UserService.updateUserDataUrl(id),
                    method: "POST"
                })
                    .then(() => {
                        setMessage({...message, show: true, text: "Aggiornamento effettuato con successo", severity: "success"})
                        fetchUser()
                    })
                    .catch((err) => {
                        setMessage({
                            ...message,
                            show: true,
                            text: "Aggiornamento non andato a buon fine",
                            severity: "error"
                        })
                    })
            }
        }
    }

    return (
        <main>
            <Box style={{background: alpha(canonTheme.palette.primary.main, 1)}}>
                <Container>
                    <Box width={'100%'} maxWidth={'90px'}>
                        <img src={logo} width={'100%'}
                             style={{margin: '1rem 0', borderRadius: '0.6rem', filter: 'brightness(0) invert(1)'
                             }}/>
                    </Box>
                </Container>
            </Box>
            <Container sx={{paddingY: '2rem', background: 'white'}}>
                <form onSubmit={handleSubmitForm}>
                    <Button LinkComponent={Link} to={"/dashboard"} variant="outlined" sx={{marginBottom: '2rem'}}
                            startIcon={<ArrowBack/>}>Torna all'elenco</Button>
                    {(loadingUserData || loadingUpdate) && <CustomLoader/>}
                    <Typography gutterBottom variant={"h4"} sx={{textAlign: 'left'}}>{userData?.nome} {userData?.cognome}</Typography>
                    {/*userData &&
                        <Accordion defaultExpanded sx={{background: "#5db8b214"}}>
                            <AccordionSummary expandIcon={<ExpandMore/>} sx={{alignItems: 'center'}}>
                                <Typography variant={"h6"} textAlign={'center'}>
                                    Attività su Mailchimp
                                </Typography>
                                <Chip label={`${userData.mailchimp?.activity?.length||0} azioni`}
                                      color={'primary'}
                                      size={'small'}
                                      sx={{margin: 0.5, marginLeft: 1}}
                                />
                            </AccordionSummary>
                            <AccordionDetails>
                                <CustomizedTimeline mailchimpActivity={userData.mailchimp?.activity}/>
                            </AccordionDetails>
                        </Accordion>
                    */}
                    <Box mt={2} style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <Typography color={canonTheme.palette.primary.main} variant={"h5"}>Dati utente</Typography>
                        {<Button
                            sx={{marginLeft: 'auto'}}
                            onClick={handleSubmitForm}
                            disabled={!isValid()}
                            startIcon={<Save/>}
                        >
                            SALVA
                        </Button>}
                    </Box>
                    {
                        userData && formik.values && formStructure.steps.map((section, i) => {
                            if (section.editable) {
                                return <Card key={i} sx={{padding: '1rem', margin: '0.5rem 0'}} variant={'outlined'} >
                                    <Typography color={canonTheme.palette.primary.main} variant={"h6"} sx={{textAlign: 'left'}}>{section.label}</Typography>
                                    <Grid container spacing={3}>
                                        {
                                            (section.fields).map((field, i) => {
                                                if(!!field.conditionsOr) {
                                                    const res = _.some(field.conditionsOr, function (o) {
                                                        return formik.values[o.id] === o.value
                                                    })
                                                    if(res)
                                                        return <CustomField key={i} formik={formik} field={field}/>
                                                } else if (!(field.condition
                                                    && formik.values[field.condition.id] !== field.condition.value))
                                                    return <CustomField key={i} formik={formik} field={field}/>
                                            })
                                        }
                                    </Grid>
                                </Card>
                            }
                        })
                    }
                    <Fab //type="submit"
                         onClick={handleSubmitForm}
                         disabled={!isValid()}
                         color={'primary'}
                         sx={{
                             top: 'auto',
                             right: '20px',
                             bottom: '20px',
                             left: 'auto',
                             position: 'fixed',
                             zIndex: 1010,
                             //backgroundColor: 'lightgrey',
                         }}>
                        <Save/>
                    </Fab>
                </form>
            </Container>
            <Snackbar open={message.show} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert elevation={6} variant={"filled"} onClose={handleCloseAlert} severity={message.severity}
                       sx={{width: '100%'}}>
                    {message.text}
                </Alert>
            </Snackbar>
        </main>
    )
}

export default User
