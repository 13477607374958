import React, {useEffect, useState} from "react";
import {Box, CircularProgress, Container, Typography} from "@mui/material";
import axios from "axios";
import {API_URL} from "../config";
import {Link, useParams} from "react-router-dom";
import logo from '../assets/images/header_2ndStage_black.png'

export const LiveStreaming = ({live, test}) => {
    const {hash} = useParams()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    useEffect(() => {
        setLoading(true)
        checkHash().then(() => setLoading(false))
    }, [])

    const checkHash = async () => {
        if (!test) {
            await axios.post(`${API_URL}/start-streaming/${hash}`)
                .catch(() => setError(true))
        }
    }

    return (
        <Box
            sx={{background: 'linear-gradient(180deg, rgba(235,235,235,1) 93%, rgba(235,235,235,1) 93%, rgba(37,173,133,1) 93%, rgba(37,173,133,1) 100%)'}}>
            <Box sx={{position: "absolute", width: 'min(16rem, 100%)', padding: '1rem', top: 0}}>
                <img src={logo} style={{width: '100%'}}/>
            </Box>
            <Container maxWidth={'lg'} sx={{height: 'max(100vh, 58rem)', display: 'flex', alignItems: 'center'}}>

                {(!error && !loading) && <Box sx={{width: '100%'}}>
                    {!live && <Typography variant={"h5"} fontWeight={"bold"} textAlign={"center"} sx={{pb: 2}}>
                        L'evento inizierà il <b style={{color: '#D50115'}}>18 gennaio alle ore 09:30</b>
                    </Typography>}
                    <Box sx={{width: '100%'}}>
                        <img style={{width: '100%'}} src={require('../assets/images/tappo-fine.png')}/>
                    </Box>
                </Box>}
                {loading &&
                    <Box sx={{width: '100%', textAlign: 'center'}}>
                        <CircularProgress/>
                    </Box>
                }
                {error &&
                    <Box sx={{width: '100%'}}>
                        <Typography variant={"h4"} fontWeight={"bold"} textAlign={'center'} color={"#D50115"}>
                            Il link non è valido
                        </Typography>
                    </Box>
                }
            </Container>
        </Box>
    )
}
