export const formMap = {
    anagrafica: [
        {
            id:'nome',
            label:"Nome",
            type:"text",
            required:true
        },
        {
            id:'cognome',
            label:"Cognome",
            type:"text",
            required:true
        },
        {
            id:'azienda',
            label:"Azienda*",
            type:"select",
            required:true,
            values:[
                "Canon Italia",
                "Canon Solutions Italia"
            ]
        },
        {
            id:'divisione',
            label:"BU/ Funzione di appartenenza*",
            type:"select",
            required:true,
            values:[
                "BO",
                "CCMS",
                "DP&S",
                "DP&S Affiliates",
                "DP&S Commercial Operations",
                "DP&S Direct Sales WS",
                "DP&S Indirect Sales",
                "DP&S Marketing & Innovation",
                "DP&S Production Direct Sales",
                "DP&S Service & Support",
                "Finance & Admin",
                "HR",
                "IP",
                "IT",
                "ITCG",
                "Legal",
                "MD Office",
                "PSC",
                "Sustainability Group"
            ]
        },
        {
            id:'email',
            label:"E-mail Aziendale",
            type:"email",
            required:true
        },
        {
            id:'telefono',
            label:"Telefono Aziendale",
            type:"number",
            required:true
        },
        {
            id:'partecipazione',
            label:"Partecipazione",
            type:"radio",
            required:true,
            values: ["Da remoto", "Non partecipo"]
        },
    ],
    presenza:[
        {
            id:'lunch',
            label:"Partecipazione al light lunch",
            type:"radio",
            required:true,
            values: ["Si","No"]
        },
        {
            id:'intolleranze',
            label:"Intolleranze/allergie alimentari",
            type:"text",
            multiline:true,
            required:false
        },
        {
            id:'cuffie',
            label:"Necessità di cuffie per traduzione da inglese a italiano",
            type:"checkbox",
            required:false,
            col:12
        },
    ]
}
