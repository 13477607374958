export const fieldTypes = {
    TEXT: {type: "text"},
    EMAIL: {type: "email"},
    SELECT: {type: "select"},
    DATE: {type: "date"},
    DATE_TIME: {type: "datetime"},
    TIME: {type: "time"},
    RADIO_GROUP: {type: "radiogroup"},
    CHECK_BOX: {type: "checkbox", md: 12},
    TEXT_BOX: {type: "textbox"},
    CUSTOM: {type: "custom"}
}