import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    ListItem,
    ListItemIcon
} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import CustomLoader from "./CustomLoader";
import ListItemText from "@mui/material/ListItemText";
import {Headset, HeadsetOff, Info, NoMeals, Restaurant} from "@mui/icons-material";

const ConfirmCheckinDialog = ({open, handleClose, user, info = false}) => {
  return (
      <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
          <DialogTitle id="alert-dialog-title">
              <Box display={'flex'} alignItems={'center'}>
                  <Info color={'primary'} sx={{mr: 2}}/>
                  {info ? "Informazioni" : "Checkin avvenuto con successo"}
              </Box>
          </DialogTitle>
          <DialogContent>
              <DialogContent id="alert-dialog-description">
                  {!user && <CustomLoader/>}
                  {user ?
                      <Box>
                          <Typography gutterBottom variant={"h6"}>{user?.nome} {user?.cognome}</Typography>
                          <Divider/>
                          <ListItem disablePadding>
                              <ListItemIcon>
                                  {user?.lunch === 'Si' ? <Restaurant/> : <NoMeals/>}
                              </ListItemIcon>
                              <ListItemText
                                  primary={"Pranzo: "+ (user?.lunch || '-')}
                                  secondary={user?.lunch && user?.lunch === 'Si' ? ("Intolleranze: " + (user?.intolleranze || '-')) : ''}
                              />
                          </ListItem>
                          <Divider/>
                          <ListItem disablePadding>
                              <ListItemIcon>{user?.cuffie === true ? <Headset/> : <HeadsetOff/>}</ListItemIcon>
                              <ListItemText primary={"Cuffie: "+
                                  (user?.cuffie === true ? 'Si'
                                      : user?.cuffie === false ? 'No'
                                          : '-')}/>
                          </ListItem>
                          <Divider/>
                      </Box> : null}
              </DialogContent>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleClose} autoFocus>
                  OK
              </Button>
          </DialogActions>
      </Dialog>
  )
}

export default ConfirmCheckinDialog;
