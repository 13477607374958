import React from "react";
import {Box, Container, Typography} from "@mui/material";

export const Chiusura = () => {
  return(
      <Container maxWidth={'md'} sx={{py: 3}}>
          <Box sx={{width: '100%', margin: 'auto', p: 3, mb: 6}}>
              <img src={require('../assets/images/header.png')} style={{width: '100%'}}/>
          </Box>
          <Typography variant={"h4"} fontWeight={"bold"} textAlign={"center"} textTransform={'uppercase'} sx={{py: 2}}>
              le iscrizioni all'evento sono chiuse
          </Typography>
          <Typography textAlign={"center"}>
              In caso di necessità si prega di contattere la segreteria organizzativa:<br/><a href={'mailto:segreteria@believetoachieve2023.canon.it'} target={'_blank'}>segreteria@believetoachieve2023.canon.it</a>
          </Typography>
      </Container>
  )
}
