import React from "react";
import {Box, CircularProgress, Container, Typography} from "@mui/material";
import logo from "../assets/images/header_2ndStage_black.png";

export const StreamingBackup = () => {
    return (
        <Box
            sx={{background: 'linear-gradient(180deg, rgba(235,235,235,1) 93%, rgba(235,235,235,1) 93%, rgba(37,173,133,1) 93%, rgba(37,173,133,1) 100%)'}}>
            <Box sx={{position: "absolute", width: 'min(16rem, 100%)', padding: '1rem', top: 0}}>
                <img src={logo} style={{width: '100%'}}/>
            </Box>
            <Container maxWidth={'lg'} sx={{height: 'max(100vh, 58rem)', display: 'flex', alignItems: 'center'}}>
                <Box sx={{width: '100%'}}>
                    <div style={{padding: '56.25% 0 0 0', position: 'relative'}}>
                        <iframe
                            src={"https://vimeo.com/event/2795176/embed/2135350706"}
                            frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
                            style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
                        ></iframe>
                    </div>
                </Box>
            </Container>
        </Box>
    )
}
