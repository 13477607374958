import React, {useEffect, useState} from "react";
import {
    Box,
    Button, CircularProgress,
    Container,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Typography
} from "@mui/material";
import {SurveyForm} from "../components/SurveyForm";
import {surveyPresenzaMap} from "../assets/data/surveyPresenzaMap";
import {surveyRemotoMap} from "../assets/data/surveyRemotoMap";
import axios from "axios";
import {API_URL} from "../config";
import {useNavigate} from "react-router-dom";

export const Survey = () => {
    const [dati, setDati] = useState({})
    const [answered, setAnswered] = useState('false')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        //setAnswered(localStorage.getItem("answered"))
        navigate('/', {replace:true})
    }, [])

    const handleChangeData = (id, value) => {
        if (id === '1') {
            setDati({})
        }
        setDati(prevState => ({...prevState, [`domanda${id}`]: value}))
    }

    const sendDati = async (e) => {
        e.preventDefault()
        setLoading(true)
        await axios.post(`${API_URL}/survey`, dati)
            .then(() => {
                localStorage.setItem("answered", 'true');
                setAnswered('true')
            })
            .catch(() => setError(true))
        setLoading(false)
    }

    return (
        <Container maxWidth={'md'} sx={{py: 3}}>
            <Box sx={{width: '100%', margin: 'auto', p: 3, mb: 6}}>
                <img src={require('../assets/images/header.png')} style={{width: '100%'}}/>
            </Box>
            <Typography variant={"h4"} fontWeight={"bold"} textAlign={"center"} textTransform={'uppercase'} sx={{py: 2}}>
                Il sondaggio è chiuso
            </Typography>
        </Container>
    )
}
