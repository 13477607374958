import {Check, Close, Link} from "@mui/icons-material";
import React from "react";
import iscrizioniForm from '../data/formStructure.json'
import checkinForm from '../data/checkinFormStructure.json'
import _ from 'lodash'
import {fieldTypes} from "./utils";
import axios from "axios";
import {API_URL} from "../config";
import authHeader from "./auth-header";
import {Button, Chip, Tooltip} from "@mui/material";
import {forms} from "../pages/dashboard/Dashboard";

const columnsUrl = () => {
    return `${API_URL}config`
}

const getColumnsVisibilityModel = () => {
    //TODO: prendere colonne visibili dal server
    // return axios.get(url)
    let columnsVisibilityModel = {}
    getFieldsFromForm().forEach((field) => {
        columnsVisibilityModel = {
            ...columnsVisibilityModel,
            [field.id]: field.defaultVisible
        }
    })
    return columnsVisibilityModel
};

const getColumsPreferences = () => {
    return axios.get(columnsUrl(), { headers: authHeader() })
    //return columnsPreferences.columsToExport
};

const saveColumsToExport = (columns) => {
    return axios.post(columnsUrl(), {defaultExportColumns: columns}, {headers: authHeader()})
};

const saveDefaultVisibilityModel = (columns) => {
    return axios.post(columnsUrl(), {defaultVisibilityModel: columns}, {headers: authHeader()})
};

const getFieldsFromForm = (formIndex) => {
    if(formIndex) {
        if(formIndex === forms.CHECKIN.index) {
            return checkinForm.fields
        }
        return iscrizioniForm.steps[formIndex]
    }

    let fields = []
    iscrizioniForm.steps.forEach((step) => {
        fields = fields.concat(step.fields)
    })
    return fields
}

const getColumns = (formIndex) => {
    const cols = getFieldsFromForm(formIndex).map((field) => {
        let baseField = {
            field: field.id || field,
            headerName: field.label || field.id || (_.startCase(field)),
            flex: 1,
        }

        baseField = field.getter ? {
            ...baseField,
            valueGetter: (params) => {
                return params.row[field.getter]?.[field.id]?._seconds ?
                    new Date(params.row[field.getter]?.[field.id]?._seconds * 1000)
                    : params.row[field.getter]?.[field.id]
            }
        } : {...baseField, valueGetter: (params) => {
                return params.row[field.id]?._seconds ?
                    new Date(params.row[field.id]?._seconds * 1000)
                    : params.row[field.id]
            }}

        const formType = _.find(fieldTypes, ['type', field.type])
        switch (formType) {
            case fieldTypes.DATE:
                return {
                    ...baseField,
                    type: 'date',
                    valueFormatter: (params) => {
                        return (params.value?._seconds ?
                            new Date((params.value?._seconds * 1000)).toLocaleDateString('it-IT')
                            : params.value ?
                                new Date(params.value).toLocaleDateString('it-IT')
                                : "");
                    },
                }
            case fieldTypes.DATE_TIME:
                return {
                    ...baseField,
                    type: 'date',
                    valueFormatter: (params) => {
                        return (params.value?._seconds ?
                            new Date((params.value?._seconds * 1000)).toLocaleString('it-IT')
                            : params.value ?
                                new Date(params.value).toLocaleString('it-IT')
                                : "");
                    },
                }
            case fieldTypes.TIME:
                return {
                    ...baseField,
                    type: 'date', // TODO: capire
                    valueFormatter: (params) => {
                        return (params.value?._seconds ?
                            new Date((params.value?._seconds * 1000)).toLocaleTimeString('it-IT')
                            : params.value ?
                                new Date(params.value).toLocaleTimeString('it-IT')
                                : "");
                    },
                }
            case fieldTypes.CHECK_BOX:
                return {
                    ...baseField,
                    valueFormatter: (params) => {
                        if (params.value) {
                            return 'Sì';
                        } else return 'No';
                    },
                    renderCell: (params) => (params.value === true || params.value === '1')
                        ? <Check sx={{color: 'green'}}/>
                        : (params.value === false || params.value === '')
                            ? <Close color={'disabled'}/>
                            : '-'
                }
            case fieldTypes.RADIO_GROUP:
                return {
                    ...baseField,
                    renderCell: (params) => (params.value === 'Si')
                        ? <Check sx={{color: 'green'}}/>
                        : (params.value === 'No')
                            ? <Close color={'disabled'}/>
                            : (params.value || '-')
                }
            case fieldTypes.CUSTOM:
                return {
                    ...baseField,
                    renderCell: (params) => {
                        if(params.row.partecipazione === 'Da remoto')
                            return <Tooltip title={"Copia link streaming"}>
                                <Button
                                        size={'small'}
                                        onClick={async (event) => {
                                            event.stopPropagation()
                                            await navigator.clipboard.writeText(`https://believetoachieve2023.canon.it/live/${params.id}`)
                                        }}>
                                    <Link/>
                                </Button>
                            </Tooltip>
                        else if(params.row.partecipazione === 'In presenza')
                            return !!params.row.qrcodeUrl ? <Tooltip title={'Apri Qr Code in un\'altra finestra'}>
                                <Button href={params.row.qrcodeUrl}
                                        target={'_blank'}
                                        size={'small'}
                                        onClick={(event) => {event.stopPropagation()}}>
                                    <img src={params.row.qrcodeUrl} style={{maxWidth: '30px'}}/>
                                </Button>
                            </Tooltip> : <Chip label={'No QR Code'} size={'small'}/>
                    }
                }
            default:
                return baseField
        }
    })

    return cols;
};

const ColumnsService = {
    columnsUrl,
    getColumnsVisibilityModel,
    getColumns,
    getColumsPreferences,
    saveColumsToExport,
    saveDefaultVisibilityModel
};

export default ColumnsService;