import './App.css';
import {Route, Routes} from "react-router-dom";
import {Form} from "./pages/Form";
import Dashboard from "./pages/dashboard/Dashboard";
import ProtectedRoute from "./components/ProtectedRoute";
import User from "./pages/dashboard/User";
import Login from "./pages/dashboard/Login";
import axios from "axios";
import AuthService from "./services/auth.service";
import {LiveStreaming} from "./pages/LiveStreaming";
import Mobile from "./pages/dashboard/Mobile";
import {Chiusura} from "./pages/Chiusura";
import {Survey} from "./pages/Survey";
import {StreamingBackup} from "./pages/StreamingBackup";

axios.interceptors.request.use(
    config => {
        const token = AuthService.getToken()
        if (token) {
            config.headers.authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error)
    }
);

function App() {
    return (
        <div>
            <Routes>
                <Route path={'/'} element={<Chiusura/>}/>
                <Route path={'/login'} element={<Login/>}/>
                <Route path={'/survey'} element={<Survey/>}/>
                <Route path={'/checkin'} element={<Mobile/>}/>
                <Route path={'/live/:hash'} element={<LiveStreaming live={true} test={false}/>}/>
                <Route path={'/test-live'} element={<LiveStreaming live={true} test={true}/>}/>
                <Route path={'/backup-live/539ff428a5f68270d35901d05a1f7d74'} element={<StreamingBackup/>}/>
                <Route element={<ProtectedRoute user={localStorage.user}/>}>
                    <Route path={'/dashboard'} element={<Dashboard/>}/>
                    <Route path={'/dashboard/user/:id'} element={<User/>}/>
                </Route>
            </Routes>
        </div>
    );
}

export default App;
