import {createTheme, responsiveFontSizes} from "@mui/material";

export const canonTheme = responsiveFontSizes(createTheme({
    palette:{
        background:{
            default:'#EBEBEB'
        },
        primary:{
            main:'#0071b8'
        }
    }
}))
